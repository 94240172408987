import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { setData } from "../../../redux/session";
import { H1 } from "../../../components/Title";
import { Main, Header, Article } from "../../../components/CenterLayout";

import { onSubmit } from "./redux";

const SignInConfidantPage = ({
  onSubmit = () => {},
  location: {
    query: { client_id, redirect_uri, user_data, token }
  },
  setData
}) => {
  const [errors, setErrors] = useState(undefined);

  useEffect(() => {
    PISauthorization().catch(console.error);
  }, []);

  const PISauthorization = async () => {
    if (user_data && token) {
      await setData({ token });
      const { errors } = await onSubmit({
        user_data: user_data.replace(/ /g, "+")
      });
      if (errors) {
        setErrors(errors);
      }
    }
  };

  return (
    <Main id="sign-in-page">
      <Header>
        <H1>
          ВХІД У ЕЛЕКТРОННУ СИСТЕМУ <br /> ОХОРОНИ ЗДОРОВ’Я
        </H1>
      </Header>
      <Article>
        {!client_id && <p>Не вказано адресу зворотнього визову</p>}
        {!redirect_uri && (
          <p>Не вказаний ідентифікатор додатку для авторизації</p>
        )}
        {!user_data && <p>Не вказано дані для авторизації</p>}
        {!token && <p>Не вказано токен особи для авторизації</p>}
        {errors && [
          <h3>Сталася помилка</h3>,
          errors.map((e, index) => <p key={index}>{e}</p>)
        ]}
      </Article>
    </Main>
  );
};

export default compose(connect(null, { onSubmit, setData }))(
  SignInConfidantPage
);
