import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Field,
  Form,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";

import {
  Main,
  Header,
  Article,
  NarrowContainer
} from "../../../components/CenterLayout";
import { H1 } from "../../../components/Title";

import styles from "./styles.module.css";
import { signUpMessages } from "../../../helpers/pisErrors";

export default class SignUpPisOtpPage extends Component {
  static contextTypes = {
    reactFinalForm: PropTypes.object
  };

  componentDidMount() {
    this.context.reactFinalForm &&
      this.context.reactFinalForm.change("otp", "");
  }

  render() {
    return (
      <Main>
        <Header>
          <H1>Підтвердження номера телефону</H1>
        </Header>
        <Article>
          <NarrowContainer>
            <p>
              Будь ласка, введіть код, надісланий на Ваш телефон у
              SMS-повідомленні
            </p>
            <div className={styles.form}>
              <Field.Input name="otp" placeholder="4 цифри з СМС" />
              <Validations field="otp">
                <Validation.Required message="Обов'язкове поле" />
                <Validation.Numeric message="Дозволені тільки цифри" />
                <Validation.Length
                  options={{ min: 4, max: 4 }}
                  message="Довжина становить 4 символи"
                />
                <Validation.Submit
                  message={signUpMessages["Invalid verification code."]}
                />
              </Validations>
              <Form.Submit block>Далі</Form.Submit>
            </div>
          </NarrowContainer>
        </Article>
      </Main>
    );
  }
}
