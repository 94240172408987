import React from "react";
import styles from "./styles.module.css";

const Scope = () => (
  <section className={styles.main} id="accept-page">
    <header className={styles.header}>
      <b>Помилка</b>
    </header>
    <article className={styles.content}>
      <p>Не вказані параметри доступу до данних</p>
    </article>
  </section>
);

export default Scope;
