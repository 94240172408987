import React from "react";
import { normalizeName } from "@edenlabllc/ehealth-utils";

import DictionaryValue from "../DictionaryValue";

const AddressView = ({ address }) => {
  const {
    country,
    area,
    region,
    settlement,
    settlement_type,
    street_type,
    street,
    building,
    apartment,
    zip
  } = address;
  return (
    <>
      {zip && <>{zip}, </>}
      {country && <>{country}, </>}
      {area && (
        <>
          {/КРИМ/i.test(area)
            ? normalizeName(area)
            : `${normalizeName(area)} область`}
          ,{" "}
        </>
      )}
      {region && <>{normalizeName(region)} район, </>}
      {settlement && (
        <>
          <DictionaryValue
            dictionary="SETTLEMENT_TYPE"
            value={settlement_type || "CITY"}
          />
          &nbsp;
          {normalizeName(settlement)},&nbsp;
        </>
      )}
      <>
        {street && (
          <>
            {street_type && (
              <DictionaryValue dictionary="STREET_TYPE" value={street_type} />
            )}
            &nbsp;
            {normalizeName(street)}
            &nbsp;
          </>
        )}
        {building}
      </>
      {apartment && (
        <>
          , кв.&nbsp;
          {apartment}
        </>
      )}
    </>
  );
};

export default AddressView;
