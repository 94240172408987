import { createAction, handleAction } from "redux-actions";
import { combineReducers } from "redux";
import uniqFn from "lodash/uniq";

import * as fromUserRoles from "../../../redux/userRoles";
import { fetchPerson } from "../../../redux/user";

const setScope = createAction("AcceptPage/SET_SCOPE");

export const fetchScope = (clientId) => async (dispatch) => {
  const getScopes = (action) => {
    if (!Object.keys(action.payload.entities).length) return null;

    const roles = Object.values(action.payload.entities.userRoles);
    const scope = roles.reduce((prev, cur) => `${prev} ${cur.scope}`, "");
    const scopeArr = uniqFn(scope.split(" ").filter((i) => i));
    dispatch(setScope(scopeArr.join(" ")));
    return action;
  };

  const userRoles = await dispatch(
    fromUserRoles.fetchRoles({ client_id: clientId })
  );
  if (userRoles.error) return userRoles;
  const checkUserScopes = getScopes(userRoles);
  if (checkUserScopes) return userRoles;

  const globalRoles = await dispatch(
    fromUserRoles.fetchGlobalRoles({ client_id: clientId })
  );
  if (globalRoles.error) return globalRoles;
  const checkGlobalScopes = getScopes(globalRoles);
  if (checkGlobalScopes) return globalRoles;

  const scope = "empty_roles";
  return dispatch(setScope(scope));
};

export const fetchPersonData = () => (dispatch) => dispatch(fetchPerson());

const scope = handleAction(setScope, (state, action) => action.payload, []);

export default combineReducers({
  scope
});
