import React from "react";
import classnames from "classnames";
import format from "date-fns/format";

import { Form } from "@edenlabllc/ehealth-components";

import {
  Main,
  Header,
  Article,
  NarrowContainer
} from "../../../components/CenterLayout";
import { H1 } from "../../../components/Title";
import DictionaryValue from "../../../components/DictionaryValue";
import AddressView from "../../../components/AddressView";
import { maskPhone } from "../../../helpers/phone";

import styles from "./styles.module.css";

const SignUpPisPersonPage = ({ person, errors, location }) => {
  if (errors && errors.length) {
    return (
      <Article>
        <h2 className={styles.errorsTitle}>Сталася помилка</h2>
        {errors.length > 1 ? (
          <ul className={styles.errorList}>
            {errors.map((e, index) => (
              <li key={index} className={styles.errorItem}>
                {e}
              </li>
            ))}
          </ul>
        ) : (
          <p>{errors[0]}</p>
        )}
      </Article>
    );
  }
  if (!person) return null;
  const isConfidant = location.pathname.includes("confidant");

  return (
    <Main>
      <Header>
        <H1>Реєстрація</H1>
      </Header>
      <Article>
        <h3 className={styles.subtitle}>
          Будь ласка, перевірте надану інформацію для реєстрації
        </h3>
        <div className={styles.content}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={classnames(styles.col, styles.title)}>
                Пацієнт
              </div>
              <div className={classnames(styles.colRight, styles.title)}>
                {`${person.last_name} ${person.first_name} ${person.second_name}`}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Стать</div>
              <div>
                <DictionaryValue dictionary="GENDER" value={person.gender} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Дата народження</div>
              <div>{format(person.birth_date, "DD.MM.YYYY")}</div>
            </div>
            {!person.no_tax_id && (
              <div className={styles.row}>
                <div className={styles.col}>ІПН</div>
                <div>{person.tax_id}</div>
              </div>
            )}
            <div className={classnames(styles.row, styles.space)}>
              <div className={styles.col}>Країна народження</div>
              <div>{person.birth_country}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>Місце народження</div>
              <div>{person.birth_settlement}</div>
            </div>
            {person.email && (
              <div className={classnames(styles.row, styles.space)}>
                <div className={styles.col}>Email</div>
                <div>{person.email}</div>
              </div>
            )}
            {person.preferred_way_communication && (
              <div className={styles.row}>
                <div className={styles.col}>Бажаний метод комунікації</div>
                <div>{person.preferred_way_communication}</div>
              </div>
            )}
            {person.secret && (
              <div className={styles.row}>
                <div className={styles.col}>Секретне слово</div>
                <div>{person.secret}</div>
              </div>
            )}
          </div>

          {person.phones && (
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.title}>Телефонні номери</div>
              </div>
              {person.phones.map((phone, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    <DictionaryValue
                      dictionary="PHONE_TYPE"
                      value={phone.type}
                    />
                  </div>
                  <div>{phone.number}</div>
                </div>
              ))}
            </div>
          )}

          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.title}>Методи аутентифікації</div>
            </div>
            {person.authentication_methods.map((method, index) =>
              isConfidant ? (
                <div key={index} className={index !== 0 ? styles.space : {}}>
                  <div className={styles.row}>
                    <div className={styles.col}>Тип</div>
                    <div>
                      <DictionaryValue
                        dictionary="AUTHENTICATION_METHOD"
                        value={method.type}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>Тип зв'язку</div>
                    <div>{method.alias}</div>
                  </div>
                </div>
              ) : (
                <div
                  className={classnames(
                    styles.row,
                    index !== 0 && styles.space
                  )}
                  key={index}
                >
                  <div className={styles.col}>
                    <DictionaryValue
                      dictionary="AUTHENTICATION_METHOD"
                      value={method.type}
                    />
                  </div>
                  <div>{maskPhone(method.phone_number)}</div>
                </div>
              )
            )}
          </div>

          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.title}>Документи</div>
            </div>
            {person.documents.map((document, index) => (
              <DocumentView
                document={document}
                key={index}
                withSpace={index !== 0}
              />
            ))}
          </div>

          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.title}>Адреси</div>
            </div>
            {person.addresses.map((address, index) => (
              <div
                className={classnames(styles.row, index !== 0 && styles.space)}
                key={index}
              >
                <div className={styles.col}>
                  Проживання/розташування/перебування
                </div>
                <div className={styles.colRight}>
                  <AddressView address={address} />
                </div>
              </div>
            ))}
          </div>

          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.title}>
                Контакт для екстренного зв'язку
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>ПІБ</div>
              <div>
                {person.emergency_contact.last_name}{" "}
                {person.emergency_contact.first_name}{" "}
                {person.emergency_contact.second_name}
              </div>
            </div>
            {person.emergency_contact.phones.map((phone, index) => (
              <div className={styles.row} key={index}>
                <div className={styles.col}>
                  <DictionaryValue dictionary="PHONE_TYPE" value={phone.type} />
                </div>
                <div>{phone.number}</div>
              </div>
            ))}
          </div>

          {isConfidant && (
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.title}>Довірена особа</div>
              </div>
              <div className={styles.row}>
                <div className={styles.col}>ПІБ</div>
                <div>
                  {person.confidant_person.last_name}{" "}
                  {person.confidant_person.first_name}{" "}
                  {person.confidant_person.second_name}
                </div>
              </div>
            </div>
          )}
          {isConfidant && (
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.title}>
                  Документи, що підтверджують повноваження представника
                </div>
              </div>
              {person.confidant_person.documents_relationship.map(
                (document, index) => (
                  <DocumentView
                    document={document}
                    key={index}
                    withSpace={index !== 0}
                  />
                )
              )}
            </div>
          )}
        </div>

        <NarrowContainer>
          <Form.Submit block>Прийняти та продовжити</Form.Submit>
        </NarrowContainer>
      </Article>
    </Main>
  );
};

const DocumentView = ({ document, withSpace }) => (
  <div className={withSpace ? styles.space : {}}>
    <div className={styles.row}>
      <div className={styles.col}>Тип</div>
      <div className={styles.colRight}>
        <DictionaryValue dictionary="DOCUMENT_TYPE" value={document.type} />
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.col}>Номер</div>
      <div>{document.number}</div>
    </div>
    <div className={styles.row}>
      <div className={styles.col}>Дата видачі</div>
      <div>{format(document.issued_at, "DD.MM.YYYY")}</div>
    </div>
    <div className={styles.row}>
      <div className={styles.col}>Виданий</div>
      <div className={styles.colRight}>{document.issued_by}</div>
    </div>
    {document.expiration_date && (
      <div className={styles.row}>
        <div className={styles.col}>Дійсний до</div>
        <div>{format(document.expiration_date, "DD.MM.YYYY")}</div>
      </div>
    )}
  </div>
);

export default SignUpPisPersonPage;
