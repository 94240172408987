import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Button } from "@edenlabllc/ehealth-components";

import env from "../../../env";
import { H1 } from "../../../components/Title";
import { ButtonsGroup } from "../../../components/Button";
import {
  Main,
  Header,
  Article,
  NarrowContainer
} from "../../../components/CenterLayout";
import SignInForm from "../../forms/SignInForm";

import { onSubmit, idGovUaAuthenticate } from "./redux";

import styles from "./styles.module.css";

const SignInPage = ({
  onSubmit = () => {},
  idGovUaAuthenticate,
  location,
  location: {
    query: { client_id, redirect_uri, scope, email, user_data }
  },
  router,
  googleReCaptchaProps
}) => {
  const [errors, setErrors] = useState(undefined);

  useEffect(() => {
    PISauthorization().catch(console.error);
  }, [user_data]);

  const PISauthorization = async () => {
    if (user_data) {
      const { errors } = await onSubmit({
        user_data: user_data.replace(/ /g, "+")
      });
      if (errors) {
        setErrors(errors);
      }
    }
  };

  return (
    <Main id="sign-in-page">
      <Header>
        <H1>
          ВХІД У ЕЛЕКТРОННУ СИСТЕМУ <br /> ОХОРОНИ ЗДОРОВ’Я
        </H1>
      </Header>
      <Article>
        {!client_id && <p>Не вказано адресу зворотнього визову</p>}
        {!redirect_uri && (
          <p>Не вказаний ідентифікатор додатку для авторизації</p>
        )}
        {client_id && redirect_uri && !user_data && (
          <NarrowContainer>
            <SignInForm
              onSubmit={async (data) => {
                const token = await googleReCaptchaProps.executeRecaptcha(
                  "SignInPage"
                );
                return onSubmit(data, token);
              }}
              initialValues={{ email }}
            />
            {env.REACT_APP_DIGITAL_SIGNATURE_SIGN_IN_ENABLED && (
              <div className={styles.buttonWrapper}>
                <Button
                  color="blue"
                  onClick={() =>
                    router.push({
                      ...location,
                      pathname: "/sign-in/digital-signature"
                    })
                  }
                  block
                  sizes="xs"
                >
                  увійти за допомогою ЕЦП
                </Button>
              </div>
            )}
            {env.REACT_APP_ID_GOV_UA_SIGN_IN_ENABLED && (
              <div className={styles.buttonWrapper}>
                <Button
                  sizes="xs"
                  onClick={async () => {
                    const token = await googleReCaptchaProps.executeRecaptcha(
                      "IdGovUaSignIn"
                    );
                    return idGovUaAuthenticate({
                      token,
                      client_id,
                      redirect_uri,
                      scope
                    });
                  }}
                  block
                >
                  увійти за допомогою GOV ID
                </Button>
              </div>
            )}
            <ButtonsGroup>
              {env.REACT_APP_DS_SIGN_UP_ENABLED && (
                <Link
                  to={{ ...location, pathname: "/sign-up" }}
                  className={styles.link}
                >
                  Зареєструватися
                </Link>
              )}
              <Link
                to={{ ...location, pathname: "/update-password" }}
                className={styles.link}
              >
                Змінити пароль
              </Link>
              <Link
                to={{ ...location, pathname: "/reset" }}
                className={styles.link}
              >
                Забули пароль?
              </Link>
            </ButtonsGroup>
          </NarrowContainer>
        )}
        {errors && [
          <h3>Сталася помилка</h3>,
          errors.map((e, index) => <p key={index}>{e}</p>)
        ]}
      </Article>
    </Main>
  );
};

export default compose(
  withRouter,
  withGoogleReCaptcha,
  connect(null, { onSubmit, idGovUaAuthenticate })
)(SignInPage);
