import React from "react";
import { withRouter } from "react-router";

import { H2 } from "../../../components/Title";

import styles from "./styles.module.css";

const ConditionPage = () => (
  <section className={styles.main} id="condition-page">
    <header className={styles.header}>
      <H2>
        Положення про конфіденційність та Політика щодо захисту персональних
        даних та конфіденційної інформації
      </H2>
    </header>
    <article className={styles.content}>
      Всі персональні дані, які надаються Національній Службі Здоров’я у процесі
      використанні сайту, будуть оброблятися відповідно до Положення про
      конфіденційність сайту. Якщо ви реєструєтесь або відвідуєте сайт як НПА,
      Учасник або Привілейований Учасник, додатково застосовується Політика щодо
      захисту персональних даних та конфіденційної інформації для НПА та
      Учасників. Якщо ви реєструєтесь або відвідуєте сайт як НСП, додатково
      застосовується Політика щодо захисту персональних даних та конфіденційної
      інформації для НСП. Вся інформація, яка надається Національній Службі
      Здоров’я під час використання сайту має бути достовірною, повною та
      актуальною. Якщо у нас будуть підстави вважати, що було надано
      недостовірну, неповну та неактуальну інформацію, доступ до сайту може бути
      обмежено або заблоковано.
    </article>
  </section>
);

export default withRouter(ConditionPage);
