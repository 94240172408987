import React, { Component } from "react";
import jwtDecode from "jwt-decode";

import {
  Async,
  Connect,
  StepForm,
  SUBMIT_ERROR
} from "@edenlabllc/ehealth-components";
import { filterPropertiesByKey } from "@edenlabllc/ehealth-utils";

import env from "../../../env";
import { fetchDictionaries } from "../../../redux/dictionaries";
import { sendOtp, register, registerIdGovUa } from "../../../redux/cabinet";
import { login } from "../../../redux/session";
import { authorize } from "../../../redux/auth";
import { getUser } from "./redux";

const ENTRIES_PAGES_MAPPING = [
  [["$.password"], "/sign-up/user"],
  [["$.otp"], "/sign-up/otp"]
];

const SignUpNextPage = (props) => <FormConnector {...props} />;

const FormConnector = (props) => (
  <Connect
    mapDispatchToProps={{
      fetchDictionaries,
      sendOtp,
      registerUser,
      registerUserByIdGovUa,
      authorizeUser,
      getUser
    }}
  >
    {({ fetchDictionaries, ...actions }) => (
      <Async await={fetchDictionaries}>
        <SignUpNextForm {...actions} {...props} />
      </Async>
    )}
  </Connect>
);

export default SignUpNextPage;

class SignUpNextForm extends Component {
  state = {
    user: {}
  };

  componentDidMount() {
    const { user_request_id } = this.tokenData;

    if (user_request_id) {
      const fetchData = async () => {
        const response = await this.props.getUser(
          this.props.location.query.token
        );
        if (response.error) {
          this.handleFailure(response.error);
        }

        this.setState({
          user: filterPropertiesByKey(response, [
            "id",
            "address",
            "email",
            "phone"
          ])
        });
      };
      fetchData();
    }
  }

  render() {
    const { user_request_id } = this.tokenData;
    return (
      <StepForm
        initialValues={this.initialValues}
        step={this.currentStep}
        transitions={{
          person: this.personTransition,
          user: this.userTransition,
          ...(!user_request_id && { otp: this.otpTransition })
        }}
        onSubmit={
          user_request_id ? this.handleSubmitIdGovUa : this.handleSubmit
        }
      >
        {React.cloneElement(this.props.children, {
          isIdGovUA: Boolean(user_request_id),
          ds: this.props.location.state.ds
        })}
      </StepForm>
    );
  }

  get initialValues() {
    const { location } = this.props;
    const { user } = this.state;
    const ds = location.state.ds;

    const { email: JWTEmail } = this.tokenData;

    if (ds) {
      return {
        person: {
          last_name: ds.privateKeyOwner.surname,
          email: JWTEmail,
          tax_id: ds.privateKeyOwner.subjDRFOCode,
          emergency_contact: { phones: [{ type: "MOBILE" }] },
          authentication_methods: [{ type: "OTP" }]
        },
        local: {
          document: { type: "PASSPORT" },
          contactPhoneMatchesAuth: true
        }
      };
    }
    return {
      person: {
        ...user,
        emergency_contact: { phones: [{ type: "MOBILE" }] },
        authentication_methods: [{ type: "OTP" }]
      },
      local: {
        document: { type: "PASSPORT" },
        contactPhoneMatchesAuth: true
      }
    };
  }

  get currentStep() {
    return this.props.location.pathname.replace("/sign-up/", "");
  }

  get tokenData() {
    try {
      return jwtDecode(this.props.location.query.token);
    } catch (e) {
      return {};
    }
  }

  personTransition = () => {
    const { router, location } = this.props;
    router.push({ ...location, pathname: "/sign-up/user" });
  };

  userTransition = async ({ person }) => {
    const { sendOtp, router, location } = this.props;

    try {
      const {
        error,
        payload: { response }
      } = await sendOtp({
        factor: person.authentication_methods[0].phone_number,
        type: "SMS"
      });

      if (error) throw response.error;
      router.push({ ...location, pathname: "/sign-up/otp" });
    } catch (error) {
      return this.handleFailure(error);
    }
  };

  otpTransition = () => {
    const { router, location } = this.props;
    router.push({ ...location, pathname: "/sign-up/sign" });
  };

  handleSubmit = async ({ password, otp, ...personData }) => {
    const { registerUser, authorizeUser, location } = this.props;

    const { client_id, redirect_uri, scope } = location.query;

    const authRequest = {
      clientId: client_id,
      redirectUri: redirect_uri,
      scope
    };
    const ds = location.state.ds;

    try {
      const content = getPersonContent(personData);

      const signed_content = env.REACT_APP_DIGITAL_SIGNATURE_ENABLED
        ? await ds.signData(content)
        : btoa(unescape(encodeURIComponent(content)));
      await registerUser({
        signed_content,
        password,
        otp,
        drfo: ds.privateKeyOwner.subjDRFOCode,
        given_name: ds.privateKeyOwner.givenName,
        surname: ds.privateKeyOwner.surname
      });

      return authorizeUser(authRequest);
    } catch (error) {
      return this.handleFailure(error);
    }
  };

  handleSubmitIdGovUa = async ({ password, otp, ...personData }) => {
    const { registerUserByIdGovUa, authorizeUser, location } = this.props;

    const { token, client_id, redirect_uri, scope } = location.query;

    const authRequest = {
      clientId: client_id,
      redirectUri: redirect_uri,
      scope
    };

    try {
      const content = getPersonContent(personData);

      await registerUserByIdGovUa(
        { patient: JSON.parse(content), otp },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return authorizeUser(authRequest);
    } catch (error) {
      return this.handleFailure(error);
    }
  };

  handleFailure = (error) => {
    const { router, location } = this.props;

    if (error.type === "validation_failed") {
      const pathname = findErroredPage(error.invalid);

      if (pathname) {
        router.replace({ ...location, pathname });
        return { [SUBMIT_ERROR]: error.invalid };
      }
    }

    // We are receiving this error as 409 Conflict for now, but in order to map this
    // error to field it should be returned as 422 Unprocessable Entity exception.
    // Until this mismatch will be fixed on the backend we're are pretending that
    // we've received appropriate error type and structure.
    if (error.type === "input_name_not_matched_with_user_request") {
      const pathname = "/sign-up/person";
      router.replace({ ...location, pathname });
      const invalid = [
        {
          entry: "$.person.first_name",
          rules: [
            {
              rule: "invalid"
            }
          ]
        }
      ];
      return { [SUBMIT_ERROR]: invalid };
    }

    router.push({ ...location, pathname: `/sign-up/failure/${error.type}` });
  };
}

const getPersonContent = (formData) => {
  const { local, person } = formData;

  const addresses = calculateAddresses(
    local.addresses,
    local.residenceAddressMatchesRegistration
  );

  const documents = calculateDocuments(local.document);

  const phones = calculatePhones(
    local.contactPhoneNumber,
    person.authentication_methods[0].phone_number,
    local.contactPhoneMatchesAuth
  );

  return JSON.stringify({
    ...person,
    addresses,
    documents,
    phones
  });
};

const calculateAddresses = (addresses, residenceMatchesRegistration) => {
  if (residenceMatchesRegistration) {
    addresses.RESIDENCE = addresses.REGISTRATION;
  }

  return Object.entries(addresses).map(
    ([type, { settlement, ...address }]) => ({
      type,
      country: "УКРАЇНА",
      ...settlement,
      ...address
    })
  );
};

const calculateDocuments = ({ series, number, ...document }) => [
  { ...document, number: [series, number].filter(Boolean).join("") }
];

const calculatePhones = (
  contactPhoneNumber,
  authPhoneNumber,
  contactMatchesAuth
) => [
  {
    type: "MOBILE",
    number: contactMatchesAuth ? authPhoneNumber : contactPhoneNumber
  }
];

const registerUser = (payload) => async (dispatch) => {
  const {
    error,
    payload: { response, data }
  } = await dispatch(register(payload));

  if (error) throw response.error;

  return dispatch(login(data.access_token));
};

const registerUserByIdGovUa = (payload) => async (dispatch) => {
  const {
    error,
    payload: { response, data }
  } = await dispatch(registerIdGovUa(payload));

  if (error) throw response.error;

  return dispatch(login(data.access_token));
};

const authorizeUser = (authRequest) => async (dispatch) => {
  const {
    error,
    payload: { response, headers }
  } = await dispatch(authorize(authRequest));

  if (error) throw response.error;

  window.location = headers.get("location");
};

const findErroredPage = (invalid) => {
  const [, path] =
    ENTRIES_PAGES_MAPPING.find(([entries]) =>
      invalid.some((e) => entries.includes(e.entry))
    ) || [];

  return path;
};
