import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import jwtDecode from "jwt-decode";
import Cookie from "js-cookie";
import pick from "lodash/pick";

import {
  Form,
  Field,
  Validation,
  Validations,
  SUBMIT_ERROR
} from "@edenlabllc/ehealth-components";

import {
  Main,
  Header,
  Article,
  NarrowContainer
} from "../../../components/CenterLayout";
import { H1 } from "../../../components/Title";
import { verifyEmail } from "../../../redux/cabinet";

const SignUpVerifyPage = ({ location, router, verifyEmail }) => {
  const token = useMemo(() => Cookie.get("authorization"), []);

  const tokenData = useMemo(() => {
    try {
      return jwtDecode(token);
    } catch (e) {
      return {};
    }
  }, [token]);

  useEffect(() => {
    if (tokenData.user_request_id) {
      router.push({
        pathname: "/sign-up/person",
        query: { ...location.query, token }
      });
    } else if (location.query.user_data) {
      router.push({
        pathname: "/sign-up/pis/person",
        query: { ...location.query }
      });
    }
  }, [tokenData, token]);

  return (
    <Main>
      <Header>
        <H1>Реєстрація</H1>
      </Header>
      <Article>
        {!location.query.user_data && [
          <p>Будь ласка, надайте вашу email-адресу</p>,
          <NarrowContainer>
            <Form
              onSubmit={async ({ email }) => {
                const auth_request = pick(location.query, [
                  "client_id",
                  "redirect_uri",
                  "scope"
                ]);

                const {
                  error,
                  payload: { response }
                } = await verifyEmail({ email, auth_request });

                if (error) return { [SUBMIT_ERROR]: response.error.invalid };

                router.push({
                  pathname: "/sign-up/confirmation",
                  query: { ...location.query, email }
                });
              }}
            >
              <Field.Text name="email" placeholder="user@email.ua" />
              <Validations field="email">
                <Validation.Required message="Об'язкове поле" />
                <Validation.Email message="Невірний формат" />
                <Validation.Submit
                  rule="email_exists"
                  message="Користувач з цим email вже зареєстрований"
                />
              </Validations>
              <Form.Submit block>Далі</Form.Submit>
            </Form>
          </NarrowContainer>
        ]}
        <p>
          Вже зареєстровані?{" "}
          <Link to={{ ...location, pathname: "/sign-in" }}>Авторизуйтеся</Link>
        </p>
      </Article>
    </Main>
  );
};

export default connect(null, { verifyEmail })(SignUpVerifyPage);
