import React from "react";
import { Link } from "react-router";
import Cookie from "js-cookie";
import pick from "lodash/pick";

import { Switch } from "@edenlabllc/ehealth-components";

import Button from "../../../components/Button";
import { Main, Header, Article } from "../../../components/CenterLayout";
import { H1, H2 } from "../../../components/Title";

const SignUpFailurePage = ({ params, location }) => {
  const authRequestQueryParams = pick(location.query, [
    "client_id",
    "redirect_uri",
    "scope"
  ]);

  return (
    <Main>
      <Header>
        <H1>Помилка</H1>
      </Header>
      <Article>
        <Switch
          value={params.type}
          email_exists={
            <>
              <p>Користувач з такою email-адресою вже існує.</p>
              <Button
                to={{ pathname: "/sign-up", query: authRequestQueryParams }}
              >
                Обрати іншу email-адресу
              </Button>
            </>
          }
          tax_id_exists={
            <>
              <p>Користувач з таким ідентифікаційним номером вже існує.</p>
              <Button
                to={{
                  pathname: "/sign-in",
                  query: authRequestQueryParams
                }}
              >
                Увійти
              </Button>
            </>
          }
          jwt_expired={
            <>
              <p>Час заповнення форми вичерпався.</p>
              <p>Будь ласка, відправте форму повторно.</p>
              <Link to={{ ...location, pathname: "/sign-up/continue" }}>
                <Button>Відправити повторно</Button>
              </Link>
            </>
          }
          not_found={
            <>
              <p>Час на реєстрацію вичерпався.</p>
              <p>Будь ласка, спробуйте знову.</p>
              <Link
                to={{
                  pathname: "/sign-in",
                  query: authRequestQueryParams
                }}
                onClick={() => Cookie.remove("authorization")}
              >
                <Button>Спробувати знову</Button>
              </Link>
            </>
          }
          access_denied={
            <>
              <H2 textTransform="uppercase" color="red">
                Користувача заблоковано
              </H2>
              {/* <p>
              При виникненні запитань, будь ласка<br />
              <a href="">зверніться до служби підтримки</a>
            </p> */}
              <Button to={{ ...location, pathname: "/sign-up/continue" }}>
                Повернутися
              </Button>
            </>
          }
          invalid_user_data={
            <>
              <p>Підписаний контент некоректний або прострочений.</p>
            </>
          }
          default={
            <>
              <H2 textTransform="uppercase">Внутрішня помилка</H2>
              <p>Вибачте за тимчасові незручності</p>
              {/* <p>
              При виникненні запитань, будь ласка<br />
              <a href="">зверніться до служби підтримки</a>
            </p> */}
              <Button to={{ ...location, pathname: "/sign-up/continue" }}>
                Повернутися
              </Button>
            </>
          }
        />
      </Article>
    </Main>
  );
};

export default SignUpFailurePage;
