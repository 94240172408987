import React from "react";
import { Link } from "react-router";

import { Form } from "@edenlabllc/ehealth-components";

import {
  Main,
  Header,
  Article,
  NarrowContainer
} from "../../../components/CenterLayout";
import { H1 } from "../../../components/Title";
import DigitalSignatureForm from "../../forms/DigitalSignatureForm";

const SignPage = ({ location }) => (
  <Main>
    <Header>
      <H1>Електронний цифровий підпис</H1>
    </Header>
    <Article>
      <NarrowContainer>
        <Form.Spy>
          {({ form }) => {
            return <DigitalSignatureForm onSubmit={form.submit} />;
          }}
        </Form.Spy>
        <Link to={{ ...location, pathname: "/sign-up/otp" }}>Назад</Link>
      </NarrowContainer>
    </Article>
  </Main>
);

export default SignPage;
