import React from "react";

import DigitalSignature from "@edenlabllc/ehealth-react-iit-digital-signature";

import env from "../../../env";

const DigitalSignatureProvider = ({ children }) => (
  <DigitalSignature
    iframeSrc={env.REACT_APP_SIGN_WIDGET_URI}
    proxy={env.REACT_APP_PROXY_URL}
  >
    {children}
  </DigitalSignature>
);

export default DigitalSignatureProvider;
