import { invoke } from "./api";
import { createUrl } from "../helpers/url";
import env from "../env";

export const signUpValidation = (data) =>
  invoke(
    {
      endpoint: createUrl(
        `${env.REACT_APP_AUTH_URL}/api/pis/sign-up_validation`
      ),
      method: "POST",
      types: [
        "pis/REGISTRATION_VALIDATION_REQUEST",
        {
          type: "pis/REGISTRATION_VALIDATION_SUCCESS",
          payload: (action, state, res) => res.json().then((json) => json.data)
        },
        "pis/REGISTRATION_VALIDATION_FAILURE"
      ],
      body: data
    },
    { auth: false }
  );

export const register = (data) =>
  invoke({
    endpoint: `${env.REACT_APP_AUTH_URL}/api/pis/register`,
    method: "POST",
    types: [
      "cabinet/REGISTRATION_PIS_REQUEST",
      "cabinet/REGISTRATION_PIS_SUCCESS",
      "cabinet/REGISTRATION_PIS_FAILURE"
    ],
    body: data
  });

export const signUpConfidantValidation = (data) =>
  invoke(
    {
      endpoint: createUrl(
        `${env.REACT_APP_AUTH_URL}/api/pis/confidant/sign-up_validation`
      ),
      method: "POST",
      types: [
        "pis/REGISTRATION_CONFIDANT_VALIDATION_REQUEST",
        {
          type: "pis/REGISTRATION_CONFIDANT_VALIDATION_REQUEST",
          payload: (action, state, res) => res.json().then((json) => json.data)
        },
        "pis/REGISTRATION_CONFIDANT_VALIDATION_REQUEST"
      ],
      body: data
    },
    { auth: true, apiKey: env.REACT_APP_AUTH_API_KEY }
  );

export const registerConfidant = (data) =>
  invoke(
    {
      endpoint: `${env.REACT_APP_AUTH_URL}/api/pis/confidant/register`,
      method: "POST",
      types: [
        "cabinet/REGISTRATION_PIS_CONFIDANT_REQUEST",
        "cabinet/REGISTRATION_PIS_CONFIDANT_REQUEST",
        "cabinet/REGISTRATION_PIS_CONFIDANT_REQUEST"
      ],
      body: data
    },
    { auth: true, apiKey: env.REACT_APP_AUTH_API_KEY }
  );

export const createConfidantSessionToken = (data) =>
  invoke(
    {
      endpoint: `${env.REACT_APP_AUTH_URL}/api/pis/confidant/login`,
      method: "POST",
      types: [
        "auth/CREATE_SESSION_PIS_CONFIDANT_TOKEN_REQUEST",
        {
          type: "auth/CREATE_SESSION_PIS_CONFIDANT_TOKEN_SUCCESS",
          meta: (action, state, res) =>
            res
              .clone()
              .json()
              .then((json) => json.urgent),
          payload: (action, state, res) =>
            res
              .clone()
              .json()
              .then((json) => json.data)
        },
        "auth/CREATE_SESSION_PIS_CONFIDANT_TOKEN_FAILURE"
      ],
      body: data
    },
    { auth: true, apiKey: env.REACT_APP_AUTH_API_KEY }
  );
