import React, { useEffect } from "react";

import DigitalSignature from "@edenlabllc/ehealth-react-iit-digital-signature";

import styles from "./styles.module.css";

const DigitalSignatureForm = ({ onSubmit }) => (
  <DigitalSignature.Consumer>
    {({ ds, containerId }) => (
      <DigitalSignatureContainer
        onSubmit={onSubmit}
        ds={ds}
        containerId={containerId}
      />
    )}
  </DigitalSignature.Consumer>
);

export const DigitalStampForm = ({ onSubmit }) => (
  <DigitalSignature.Consumer>
    {({ ds, containerId }) => (
      <DigitalSignatureContainer
        onSubmit={onSubmit}
        ds={ds}
        containerId={containerId}
      />
    )}
  </DigitalSignature.Consumer>
);

const DigitalSignatureContainer = ({ onSubmit, ds, containerId }) => {
  useEffect(() => {
    ds(onSubmit);
  }, []);

  return <div id={containerId} className={styles.container} />;
};

export default DigitalSignatureForm;
