import React from "react";

const AuthorizeError = ({ error, styles }) => {
  const message = error.find((e) => e.key === "message");

  switch (message.value) {
    case "Scope is not allowed by client type.":
      return (
        <article className={styles.error}>
          <b>Помилка:</b>
          <div>
            Запитувані права доступу не дозволені для такого типу клієнта
            системи
          </div>
        </article>
      );
    default:
      return (
        <article className={styles.error}>
          <b>Помилка:</b>
          {error.map(
            (i) =>
              typeof i.value === "string" && (
                <div key={i.key}>
                  {i.value} ({i.key})
                </div>
              )
          )}
        </article>
      );
  }
};

export default AuthorizeError;
