import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import { provideHooks } from "redial";

import { Button } from "@edenlabllc/ehealth-components";
import { getFullName } from "@edenlabllc/ehealth-utils";

import { H1 } from "../../../components/Title";
import {
  NotFoundClient,
  NotFoundClientId,
  NotFoundClientRole,
  NotFoundRedirectUri,
  NotFoundScope
} from "../../../components/NotFound";
import AuthorizeError from "../../../components/AuthorizeError";
import { fetchClientById } from "../../../redux/clients";
import { authorize } from "../../../redux/auth";

import { getClientById, getUser } from "../../../reducers";
import { fetchPersonData, fetchScope } from "./redux";
import { logout } from "../../../redux/session";
import styles from "./styles.module.css";

const ApprovePage = ({
  client,
  user,
  scope,
  location: {
    query: { client_id, redirect_uri }
  },
  router,
  authorize,
  logout,
  fetchPersonData
}) => {
  const [isLoading, setLoading] = useState(false);
  const [person, setPerson] = useState(user);
  const [error, setError] = useState(null);

  const {
    email,
    first_name: firstName,
    last_name: lastName,
    second_name: secondName
  } = person;

  useEffect(() => {
    const fetchData = async () => {
      const {
        payload: { data }
      } = await fetchPersonData();
      setPerson(data);
    };
    if (!person.email) {
      fetchData();
    }
  }, []);

  const approval = () => {
    setLoading(true);
    authorize({
      clientId: client_id,
      scope,
      redirectUri: redirect_uri
    }).then(({ payload, error }) => {
      if (error) {
        setError(
          Object.entries(payload.response.error).map(([key, value]) => ({
            key,
            value
          }))
        );
        return setLoading(false);
      }
      setLoading(false);
      setError(null);
      Cookies.remove("authorization");
      return window && (window.location = payload.headers.get("location"));
    });
  };

  if (!client_id) return <NotFoundClientId />;
  if (!client) return <NotFoundClient />;
  if (!scope) return <NotFoundScope />;
  if (scope === "empty_roles") return <NotFoundClientRole />;
  if (!redirect_uri) return <NotFoundRedirectUri />;

  return (
    <section className={styles.main} id="accept-page">
      <H1>ВХІД У ЕЛЕКТРОННУ СИСТЕМУ ОХОРОНИ ЗДОРОВ’Я</H1>
      <div className={styles.user}>
        Ви авторизовані в системі як <br />
        <b>{email || getFullName({ lastName, firstName, secondName })}</b>
      </div>
      {error && <AuthorizeError error={error} styles={styles} />}
      <footer className={styles.footer}>
        <div>
          <Button disabled={isLoading} onClick={approval} size="medium">
            продовжити
          </Button>
          <div className={styles.signIn}>
            Не ваш обліковий запис?{" "}
            <a
              onClick={async () => {
                await logout();
                return router.push({
                  ...router.location,
                  pathname: "/sign-in"
                });
              }}
            >
              Увійти з іншими данними
            </a>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default compose(
  provideHooks({
    fetch: ({ dispatch, location: { query } }) =>
      Promise.all([
        dispatch(fetchClientById(query.client_id)),
        dispatch(fetchScope(query.client_id))
      ])
  }),
  withRouter,
  connect(
    (state, { location: { query } }) => ({
      scope: !!query.scope ? query.scope : state.pages.AcceptPage.scope,
      client: getClientById(state, query.client_id),
      user: getUser(state)
    }),
    { authorize, logout, fetchPersonData }
  )
)(ApprovePage);
