export const signInMessages = {
  "Invalid signed content.": "Підписаний контент некоректний або прострочений.",
  "JWT is invalid.": "Підписаний контент некоректний або прострочений.",
  "User is blocked.": "Користувача заблоковано",
  "Person not found.":
    "Персону не знайдено. Спочатку необхідно зареєструватись.",
  "Person with tax id or document number not found.":
    "Персону не знайдено. Спочатку необхідно зареєструватись.",
  "Incorrect person age for such an action.":
    "Для вказаного віку персони авторизація в системі не дозволена",
  "It is impossible to uniquely identify the person.":
    "Не вдалося ідентифікувати персону. Зверніться до служби підтримки"
};

export const signInConfidantMessages = {
  "User and patient with such data not found":
    "Персону не знайдено. Спочатку необхідно зареєструватись.",
  "Invalid tax_id format for active person search": "Некоректний формат ІПН",
  "tax_id or document, last_name, given_name fields are mandatory for search":
    "Не всі необхідні параметри для автентифікації було заповнено",
  "Invalid document type for active person search": "Некоректний тип документу",
  "Forbidden document type for active person search":
    "Некоректний тип документу",
  "Invalid document number for active person search":
    "Некоректний номер документу",
  "Relationship not confirmed":
    "Звʼязок між авторизованою особою і довіреною особою не підтверджено",
  "Unable to authenticate signer": "Не вдалося ідентифікувати підписанта",
  "Relationship not confirmed.":
    "Зв'язок між авторизованою особою і довіреною особою не підтверджено."
};

export const signUpMessages = {
  "user_data missing": "Відсутні дані для реєстрації.",
  "Invalid signed content.": "Підписаний контент некоректний або прострочений.",
  "JWT is invalid.": "Підписаний контент некоректний або прострочений.",
  "Registration person and person that sign should be the same.":
    "Дані з підпису не співпадають з даними для реєстрації.",
  "Input name doesn't match name from digital signature.":
    "Дані з підпису не співпадають з даними для реєстрації.",
  "expected true but got false for attribute patient_signed":
    "Користувач повинен погодитись з підписанням даних.",
  "expected true but got false for attribute process_disclosure_data_consent":
    "Користувач повинен погодитись з передачею даних в ЕСОЗ.",
  "Incorrect person age for such an action.":
    "Для вказаного віку персони авторизація в системі не дозволена.",
  "Submitted document type is not allowed.": "Некоректний тип документу.",
  "Document that proves person`s legal capacity must be submitted.":
    "Для вказаного віку персони необхідно вказати документи підтверджуючі дієздатність особи.",
  "Only OTP authentication method can be created for person":
    "Некоректно вказаний метод автентифікації.",
  "Invalid verification code.": "Некоректний код підтвердження.",
  "User is blocked.": "Користувача заблоковано.",
  "Person not found.":
    "Не вдалося ідентифікувати персону. Зверніться до служби підтримки.",
  "Not a base64 string": "Підписаний контент некоректний.",
  "expected value to have a minimum length of 1 but was 0":
    "Відсутні дані для реєстрації.",
  "It is impossible to uniquely identify the person.":
    "Не вдалося ідентифікувати персону. Зверніться до служби підтримки"
};

export const signUpConfidantMessages = {
  "Invalid signature": "Підписаний контент некоректний або прострочений.",
  "Invalid access token": "Не вдалося автентифікувати користувача",
  "Unable to authenticate signer.": "Не вдалося ідентифікувати підписанта.",
  "Relationship not confirmed.":
    "Зв'язок між авторизованою особою і довіреною особою не підтверджено.",
  "Person who initiates registration of patient must be submitted as confidant person.":
    "Особа що створює запит на реєстрацію має бути вказана як законний представник",
  "Person with cumulative verification status NOT_VERIFIED can not be submitted as confidant.":
    "Особа яка вказана як законний представник не верифікована",
  "Person with cumulative verification status VERIFICATION_NEEDED can not be submitted as confidant.":
    "Особа яка вказана як законний представник потребує верифікації",
  'Confidant person must have active authentication method with type "OTP" where ended_at is equal to or greater than current date.':
    "Не коректний метод авторизації особи, що вказана як законний представник",
  "Only THIRD_PERSON authentication method can be created for person.":
    "Дозволена авторизація тільки через законного представника",
  "Person who initiates registration of patient must be submitted as THIRD_PERSON.":
    "Не коректний ідентифікатор особи що автенторизує дії"
};

const handleSignUpValidationError = (error) => {
  const errors = [];
  if (error.invalid && error.invalid.length) {
    error.invalid.forEach((i) => {
      i.rules &&
        i.rules.forEach((rule) => {
          if (
            i.entry === "$.patient_signed" &&
            rule.description === "value is not allowed in enum"
          ) {
            errors.push(
              signUpMessages[
                "expected true but got false for attribute patient_signed"
              ]
            );
          } else if (
            i.entry === "$.process_disclosure_data_consent" &&
            rule.description === "value is not allowed in enum"
          ) {
            errors.push(
              signUpMessages[
                "expected true but got false for attribute process_disclosure_data_consent"
              ]
            );
          } else if (
            i.entry === "$.person.authentication_methods.[0].type" &&
            rule.description === "value is not allowed in enum"
          ) {
            errors.push(
              signUpMessages[
                "Only OTP authentication method can be created for person"
              ]
            );
          } else if (rule.description) {
            if (rule.params) {
              if (rule.rule === "required") {
                errors.push(
                  `Обов'язковий атрибут "${requiredFields[i.entry]}" відсутній`
                );
              } else if (rule.rule === "length") {
                errors.push(minItemsList[i.entry]);
              }
            } else {
              errors.push(
                signUpMessages[rule.description] ||
                  signUpConfidantMessages[rule.description] ||
                  rule.description
              );
            }
          } else {
            errors.push(
              signUpMessages[error.message] ||
                signUpConfidantMessages[error.message] ||
                error.message
            );
          }
        });
    });
  } else if (error.message) {
    errors.push(
      signUpMessages[error.message] ||
        signUpConfidantMessages[error.message] ||
        error.message
    );
  }

  return errors;
};

const requiredFields = {
  "$.person.birth_date": "Дата народження",
  "$.person.first_name": "Ім'я",
  "$.person.last_name": "Прізвище",
  "$.person.second_name": "По-батькові",
  "$.person.birth_country": "Країна народження",
  "$.person.birth_settlement": "Місце народження",
  "$.person.gender": "Стать",
  "$.person.email": "Адреса електронної пошти",
  "$.person.no_tax_id": "Ознака відсутності ІПН",
  "$.person.secret": "Кодове слово",
  "$.person.documents": "Документи",
  "$.person.documents.[0].type": "Тип документу",
  "$.person.documents.[0].number": "Номер документу",
  "$.person.documents.[0].issued_by": "Місце видачі документу",
  "$.person.documents.[0].issued_at": "Дата видачі документу",
  "$.person.documents.[0].expiration_date": "Дата закінчення дії документу",
  "$.person.addresses": "Адреса",
  "$.person.addresses.[0].type": "Тип адреси",
  "$.person.addresses.[0].country": "Країна прописки (проживання)",
  "$.person.addresses.[0].area": "Область прописки (проживання)",
  "$.person.addresses.[0].region": "Район прописки (проживання)",
  "$.person.addresses.[0].settlement": "Населений пункт прописки (проживання)",
  "$.person.addresses.[0].settlement_type":
    "Тип населеного пункту прописки (проживання)",
  "$.person.addresses.[0].settlement_id":
    "Ідентифікатор населеного пункту прописки (проживання)",
  "$.person.addresses.[0].street_type": "Тип вулиці прописки (проживання)",
  "$.person.addresses.[0].street": "Вулиця прописки (проживання)",
  "$.person.addresses.[0].building": "Будинок прописки (проживання)",
  "$.person.addresses.[0].apartment": "Квартира прописки (проживання)",
  "$.person.addresses.[0].zip": "Поштовий індекс прописки (проживання)",
  "$.person.phones": "Телефони",
  "$.person.phones.[0].type": "Тип телефону",
  "$.person.phones.[0].number": "Номер телефону",
  "$.person.authentication_methods": "Методи автентифікації",
  "$.person.authentication_methods.[0].type": "Тип методу автентифікації",
  "$.person.authentication_methods.[0].value": "Значення методу автентифікації",
  "$.person.authentication_methods.[0].alias":
    "Найменування методу автентифікації",
  "$.person.preferred_way_communication": "Бажаний спосіб комунікації",
  "$.person.confidant_person": "Довірена особа",
  "$.person.confidant_person.person_id": "Ідентифікатор довіреної особи",
  "$.person.confidant_person.documents_relationship":
    "Документ що підтверджує звʼязок з довіреною особою",
  "$.person.confidant_person.documents_relationship.[0].type":
    "Тип документу довіреної особи",
  "$.person.confidant_person.documents_relationship.[0].number":
    "Номер документу довіреної особи",
  "$.person.confidant_person.documents_relationship.[0].issued_by":
    "Місце видачі документу довіреної особи",
  "$.person.confidant_person.documents_relationship.[0].issued_at":
    "Дата видачі документу довіреної особи",
  "$.person.emergency_contact": "Контактна особа для екстрених випадків",
  "$.person.emergency_contact.first_name":
    "Імʼя контактної особи для екстрених випадків",
  "$.person.emergency_contact.last_name":
    "Прізвище контактної особи для екстрених випадків",
  "$.person.emergency_contact.second_name":
    "По-батькові контактної особи для екстрених випадків",
  "$.person.emergency_contact.phones":
    "Телефони контактної особи для екстрених випадків",
  "$.person.emergency_contact.phones.[0].type":
    "Тип телефону контактної особи для екстрених випадків",
  "$.person.emergency_contact.phones.[0].number":
    "Номер телефону контактної особи для екстрених випадків",
  "$.person.patient_signed": "Ознака підпису запиту пацієнтом",
  "$.person.process_disclosure_data_consent": "Згода з передачею даних в ЕСОЗ"
};

const minItemsList = {
  "$.person.documents":
    "Мінімум один документ особи що реєструється має бути вказаний",
  "$.person.addresses": "Мінімум одна адреса має бути вказана",
  "$.person.phones": "Мінімум один телефон має бути вказаний",
  "$.person.authentication_methods":
    "Мінімум один метод автентифікації має бути вказаний",
  "$.person.confidant_person.documents_relationship":
    "Мінімум один документ законного представника має бути вказаний",
  "$.person.emergency_contact.phones":
    "Мінімум один телефон контактної особи для екстрених випадків має бути вказаний"
};

export default handleSignUpValidationError;
