import React, { Component } from "react";
import debounce from "lodash/debounce";
import subYears from "date-fns/sub_years";

import {
  Connect,
  Field,
  Form,
  Switch,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import { formatDate, parseDate } from "@edenlabllc/ehealth-utils";

import env from "../../../env";
import { createUrl } from "../../../helpers/url";
import {
  Main,
  Header,
  Article,
  NarrowContainer
} from "../../../components/CenterLayout";
import { H1, H2 } from "../../../components/Title";

const NAME_PATTERN =
  '^(?!.*[ЫЪЭЁыъэё@%&$^#])[a-zA-ZА-ЯҐЇІЄа-яґїіє0-9№\\"!\\^\\*)\\]\\[(._-].*$';
const PERSON_NAME_PATTERN = "^(?!.*[ЫЪЭЁыъэё@%&$^#])[А-ЯҐЇІЄа-яґїіє\\'\\- ]*$";
const BUILDING_PATTERN =
  "^[1-9]((?![ЫЪЭЁыъэё])()([А-ЯҐЇІЄа-яґїіє \\/\\'\\-0-9])){0,20}$";
const SERIES_DOCUMENT_PATTERN = "^((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}$";
const NUMBER_DOCUMENT_PATTERN = "^[0-9]{6}$";
const TEMPORARY_DOCUMENT_PATTERN =
  "^(((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}[0-9]{4,6}|[0-9]{9}|((?![ЫЪЭЁ])([А-ЯҐЇІЄ])){2}[0-9]{5}\\/[0-9]{5})$";
const ONLY_NUMBER_DOCUMENT_PATTERN =
  "^(?![ЫЪЭЁыъэё@%&$^#`~:,.*|}{?!])[A-ZА-ЯҐЇІЄ0-9№\\/()-]+$";

const ID_CARD_PATTERN = "^[0-9]{9}$";
const UNZR_PATTERN = "^[0-9]{8}-[0-9]{5}$";
const UNZR_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const GROUP_DOCUMENT = {
  PASSPORT: "SERIES_NUMBER_DOCUMENT",
  COMPLEMENTARY_PROTECTION_CERTIFICATE: "SERIES_NUMBER_DOCUMENT",
  REFUGEE_CERTIFICATE: "SERIES_NUMBER_DOCUMENT",
  PERMANENT_RESIDENCE_PERMIT: "TEMPORARY_DOCUMENT",
  TEMPORARY_CERTIFICATE: "TEMPORARY_DOCUMENT",
  BIRTH_CERTIFICATE: "NUMBER_DOCUMENT",
  TEMPORARY_PASSPORT: "NUMBER_DOCUMENT",
  NATIONAL_ID: "ID_CARD"
};

const SignUpPersonPage = ({ isIdGovUA, ds }) => (
  <Main>
    <Header>
      <H1>Авторизація в системі</H1>
    </Header>
    <Article>
      {!isIdGovUA && <UserInfo ds={ds} />}
      <H2>Для продовження, додайте персональні дані</H2>
      <NarrowContainer>
        <Field.Input
          name="person.last_name"
          label="Прізвище"
          color="#292929"
          disabled
        />
        <Field.Input
          name="person.first_name"
          label="Ім'я"
          placeholder="Введіть ім'я"
          disabled={isIdGovUA}
        />
        <Validations field="person.first_name">
          <Validation.Required message="Об'язкове поле" />
          <Validation.Matches
            options={PERSON_NAME_PATTERN}
            message="Дозволені тільки літери українського алфавіту"
          />
          <Validation.Submit message="Некоректно введено ПІБ" />
          <Validation
            validate={(value) =>
              ds.privateKeyOwner.givenName
                .toUpperCase()
                .includes(value && value.toUpperCase())
            }
            message="Має збігатися з цифровим підписом"
          />
        </Validations>
        <Field.Input
          name="person.second_name"
          label="По-батькові"
          placeholder="Введіть по-батькові"
          disabled={isIdGovUA}
        />
        <Validation.Matches
          field="person.second_name"
          options={PERSON_NAME_PATTERN}
          message="Дозволені тільки літери українського алфавіту"
        />
        <Field.Input
          name="person.birth_country"
          placeholder="Введіть країну народження"
          label="Країна народження"
        />
        <Validation.Required
          field="person.birth_country"
          message="Об'язкове поле"
        />
        <Field.Input
          name="person.birth_settlement"
          placeholder="Введіть місто народження"
          label="Місто народження"
        />
        <Validations field="person.birth_settlement">
          <Validation.Required message="Об'язкове поле" />
          <Validation.Matches
            options={NAME_PATTERN}
            message="Дозволені тільки цифри та літери українського й англійського алфавіту"
          />
        </Validations>
        <Field.MaskField
          label="Дата народження"
          name="person.birth_date"
          placeholder="ДД.ММ.РРРР"
          guide={false}
          mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
          format={formatDate}
          parse={parseDate}
          horizontal
        />
        <Validations field="person.birth_date">
          <Validation.Required message="Об'язкове поле" />
          <Validation.Date message="Невірна дата" />
          <Validation.BirthDate message="Невірна дата" />
          <Validation.After
            options={subYears(new Date(), 100).toISOString()}
            message="Невірна дата"
          />
        </Validations>
        <Connect
          mapStateToProps={(state) => ({
            documentTypes: Object.entries(
              state.data.dictionaries.DOCUMENT_TYPE.values
            )
          })}
        >
          {({ documentTypes }) => (
            <Field.Select
              name="local.document.type"
              label="Документ"
              placeholder="Тип документу"
              format={(value) =>
                documentTypes.find(([key]) => key === value) || null
              }
              parse={(item) => (item == null ? undefined : item[0])}
              itemToString={(item) => (item == null ? "" : item[1])}
              renderItem={(item) => item[1]}
              items={documentTypes}
            />
          )}
        </Connect>
        <Validation.Required
          field="local.document.type"
          message="Об'язкове поле"
        />
        <Form.Spy>
          {({ values }) => {
            if (!values.local || !values.local.document) return null;
            const { type } = values.local.document;
            return (
              <Switch
                value={GROUP_DOCUMENT[type]}
                SERIES_NUMBER_DOCUMENT={
                  <Field.Row>
                    <Field.Col width={1 / 3}>
                      <Field.Input
                        name="local.document.series"
                        placeholder="Серія"
                      />
                      <Validations field="local.document.series">
                        <Validation.Required message="Об'язкове поле" />
                        <Validation.Matches
                          options={SERIES_DOCUMENT_PATTERN}
                          message="Невірна серія документу"
                        />
                      </Validations>
                    </Field.Col>
                    <Field.Col width={2 / 3}>
                      <Field.Input
                        name="local.document.number"
                        placeholder="Номер"
                      />
                      <Validations field="local.document.number">
                        <Validation.Required message="Об'язкове поле" />
                        <Validation.Matches
                          options={NUMBER_DOCUMENT_PATTERN}
                          message="Невірний номер документу"
                        />
                      </Validations>
                    </Field.Col>
                  </Field.Row>
                }
                TEMPORARY_DOCUMENT={
                  <>
                    <Field.Input
                      name="local.document.number"
                      placeholder="Номер"
                    />
                    <Validations field="local.document.number">
                      <Validation.Required message="Об'язкове поле" />
                      <Validation.Matches
                        options={TEMPORARY_DOCUMENT_PATTERN}
                        message="Невірний номер документу"
                      />
                    </Validations>
                  </>
                }
                NUMBER_DOCUMENT={
                  <>
                    <Field.Input
                      name="local.document.number"
                      placeholder="Номер"
                    />
                    <Validations field="local.document.number">
                      <Validation.Required message="Об'язкове поле" />
                      <Validation.Matches
                        options={ONLY_NUMBER_DOCUMENT_PATTERN}
                        message="Невірний номер документу"
                      />
                      <Validation.Length
                        options={{ min: 2, max: 25 }}
                        message="Довжина становить від 2 до 25 символів"
                      />
                    </Validations>
                  </>
                }
                ID_CARD={
                  <>
                    <Field.Input
                      name="local.document.number"
                      placeholder="Номер"
                    />
                    <Validations field="local.document.number">
                      <Validation.Required message="Об'язкове поле" />
                      <Validation.Matches
                        options={ID_CARD_PATTERN}
                        message="Невірний номер документу"
                      />
                    </Validations>
                    <Validation.Required
                      field="local.document.expiration_date"
                      message="Об'язкове поле"
                    />
                    <Validation.Required
                      field="person.unzr"
                      message="Об'язкове поле"
                    />
                  </>
                }
              />
            );
          }}
        </Form.Spy>
        <Field.Input
          name="local.document.issued_by"
          placeholder="Ким виданий"
        />
        <Validation.Required
          field="local.document.issued_by"
          message="Об'язкове поле"
        />
        <Field.MaskField
          name="local.document.issued_at"
          placeholder="Дата видачі"
          guide={false}
          mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
          format={formatDate}
          parse={parseDate}
        />
        <Validations field="local.document.issued_at">
          <Validation.Required message="Об'язкове поле" />
          <Validation.BirthDate message="Невірна дата" />
          <Validation.Date message="Невірна дата" />
        </Validations>

        <Field.MaskField
          name="local.document.expiration_date"
          placeholder="Дата закінчення"
          guide={false}
          mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
          format={formatDate}
          parse={parseDate}
        />
        <Validations field="local.document.expiration_date">
          <Validation.Date message="Невірна дата" />
          <Validation.MinDate
            options={new Date()}
            message="Невірна дата закінчення терміну дії документа"
          />
        </Validations>
        <Field.MaskField
          label="Запис №"
          name="person.unzr"
          guide={false}
          mask={UNZR_MASK}
          placeholder="Введіть номер"
        />
        <Validation.Matches
          field="person.unzr"
          options={UNZR_PATTERN}
          message="Невірний номер документу"
        />
        <Form.Spy>
          {({ active }) => {
            return (
              <Validation.Custom
                field="person.unzr"
                options={(values) => {
                  const unzr = values.value;
                  const birth_date = values.allValues.person.birth_date || "";
                  if (!birth_date) return true;
                  const birthDate = birth_date.replace(/-/g, "");
                  const [first] = (unzr && unzr.split("-")) || [];
                  return (
                    !first ||
                    first === birthDate ||
                    (active === "person.unzr" && first.length === 1)
                  );
                }}
                message="Невірний номер документу"
              />
            );
          }}
        </Form.Spy>
        <Field.Group label="Стать">
          <Field.Row>
            <Field.Col width={1 / 3}>
              <Field.Radio name="person.gender" label="Жіноча" value="FEMALE" />
            </Field.Col>
            <Field.Col width={1 / 3}>
              <Field.Radio name="person.gender" label="Чоловіча" value="MALE" />
            </Field.Col>
          </Field.Row>
        </Field.Group>
        <Validation.Required field="person.gender" message="Об'язкове поле" />
        <AddressFields type="REGISTRATION" label="Адреса реєстрації" />
        <Field.Checkbox
          label="Адреса проживання співпадає з адресою реєстрації"
          name="local.residenceAddressMatchesRegistration"
        />
        <Field
          name="local.residenceAddressMatchesRegistration"
          subscription={{ value: true }}
        >
          {({ input: { value } }) =>
            value || (
              <AddressFields type="RESIDENCE" label="Адреса проживання" />
            )
          }
        </Field>

        <Form.Submit block>Далі</Form.Submit>
      </NarrowContainer>
    </Article>
  </Main>
);

export default SignUpPersonPage;

const UserInfo = ({ ds }) => (
  <Field name="person.email" subscription={{ value: true }}>
    {({ input: { value: email } }) => (
      <p>
        {email}
        <br />
        ІПН: {ds.privateKeyOwner.subjDRFOCode}
      </p>
    )}
  </Field>
);

class AddressFields extends Component {
  state = {
    loaded: false,
    regions: [],
    settlements: []
  };

  async componentDidMount() {
    const regionsResponse = await fetch(
      createUrl(`${env.REACT_APP_API_URL}/api/uaddresses/regions`, {
        page_size: 30
      })
    );
    const { data: regions } = await regionsResponse.json();
    this.setState({ regions, loaded: true });
  }

  fetchSettlementsDebounced = debounce(this.fetchSettlementsSearch, 500);

  async fetchSettlementsSearch({ settlement, area }) {
    if (settlement.length < 2) {
      this.setState({ settlements: [] });
    } else {
      const settlementsResponse = await fetch(
        createUrl(
          `${env.REACT_APP_API_URL}/api/uaddresses/search/settlements`,
          {
            name: settlement,
            page_size: 200,
            region: area
          }
        )
      );
      const { data } = await settlementsResponse.json();
      this.setState({ settlements: data });
    }
  }

  render() {
    const { type, label } = this.props;
    const { regions, settlements, loaded } = this.state;

    if (!loaded) return null;

    return (
      <Field.Group label={label}>
        <Field.Select
          name={`local.addresses[${type}].area`}
          placeholder="Область"
          items={regions
            .map(({ name }) => name)
            .sort((a, b) =>
              a.replace(/м\./i, "").localeCompare(b.replace(/м\./i, ""))
            )}
          filterItems={(inputValue, item) =>
            item.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
        <Validation.Required
          field={`local.addresses[${type}].area`}
          message="Об'язкове поле"
        />
        <Connect
          mapStateToProps={(state) => ({
            settlementTypes: state.data.dictionaries.SETTLEMENT_TYPE.values
          })}
        >
          {({ settlementTypes }) => (
            <Field
              name={`local.addresses[${type}].area`}
              subscription={{ value: true }}
            >
              {({ input: { value: area } }) => (
                <Field.Select
                  name={`local.addresses[${type}].settlement`}
                  disabled={!area}
                  placeholder="Назва населеного пункту"
                  itemToString={(item) =>
                    item === null ? "" : item.settlement
                  }
                  items={settlements.map(({ name, district, id, type }) => ({
                    settlement: name,
                    settlement_type: type,
                    settlement_id: id,
                    region: district || undefined
                  }))}
                  onInputValueChange={(settlement) =>
                    settlement &&
                    this.fetchSettlementsDebounced({ settlement, area })
                  }
                  renderItem={({ settlement_type, settlement, region }) => (
                    <>
                      <small>{settlementTypes[settlement_type]}</small>{" "}
                      {settlement}
                      {region && (
                        <>
                          {", "}
                          <small>район: {region}</small>
                        </>
                      )}
                    </>
                  )}
                />
              )}
            </Field>
          )}
        </Connect>
        <Validation.Required
          field={`local.addresses[${type}].settlement`}
          message="Об'язкове поле"
        />
        <Field.Input
          name={`local.addresses[${type}].zip`}
          placeholder="Індекс"
        />
        <Validations field={`local.addresses[${type}].zip`}>
          <Validation.Matches
            options={/^\d*$/}
            message="Дозволені тільки цифри"
          />
          <Validation.Length
            options={{ min: 5, max: 5 }}
            message="Довжина становить 5 символів"
          />
        </Validations>
        <Field.Row>
          <Field.Col width={1 / 2}>
            <Connect
              mapStateToProps={(state) => ({
                streetTypes: Object.entries(
                  state.data.dictionaries.STREET_TYPE.values
                )
              })}
            >
              {({ streetTypes }) => (
                <Field.Select
                  name={`local.addresses[${type}].street_type`}
                  placeholder="Тип вулиці"
                  format={(value) =>
                    streetTypes.find(([key]) => key === value) || null
                  }
                  parse={(item) => (item == null ? undefined : item[0])}
                  itemToString={(item) => (item == null ? "" : item[1])}
                  renderItem={(item) => item[1]}
                  filterItems={(inputValue, [_key, value]) =>
                    value.toLowerCase().includes(inputValue.toLowerCase())
                  }
                  items={streetTypes}
                />
              )}
            </Connect>
          </Field.Col>
          <Field.Col width={1 / 2}>
            <Field.Input
              name={`local.addresses[${type}].street`}
              placeholder="Назва вулиці"
            />
            <Validation.Matches
              field={`local.addresses[${type}].street`}
              options={NAME_PATTERN}
              message="Дозволені тільки цифри та літери українського й англійського алфавіту"
            />
          </Field.Col>
        </Field.Row>
        <Field.Row>
          <Field.Col width={1 / 2}>
            <Field.Input
              name={`local.addresses[${type}].building`}
              placeholder="№ буд."
            />
            <Validations field={`local.addresses[${type}].building`}>
              <Validation.Required message="Об'язкове поле" />
              <Validation.Matches
                options={BUILDING_PATTERN}
                message="Невірний формат"
              />
            </Validations>
          </Field.Col>
          <Field.Col width={1 / 2}>
            <Field.Input
              name={`local.addresses[${type}].apartment`}
              placeholder="№ квартири"
            />
          </Field.Col>
        </Field.Row>
      </Field.Group>
    );
  }
}
