import { createAction, handleActions } from "redux-actions";
import Cookies from "js-cookie";

const AUTH_COOKIE_NAME = "authorization";

export const getToken = () => (dispatch, getState) =>
  Cookies.get(AUTH_COOKIE_NAME);
export const setToken = (token, expires_at) => (dispatch, getState) => {
  Cookies.set(AUTH_COOKIE_NAME, token, {
    expires: new Date(expires_at * 1000)
  });
};
export const removeToken = () => (dispatch, getState) =>
  Cookies.remove(AUTH_COOKIE_NAME);

export const isAuthenticated = () => (dispatch) =>
  dispatch(getToken()).then((resp) => !!resp);

export const logoutAction = createAction("session/LOGOUT");
export const setData = createAction("session/SET_DATA");

export const loadTokenFromStorage = () => (dispatch, getState) =>
  dispatch(
    setData({
      token: Cookies.get(AUTH_COOKIE_NAME)
    })
  );

export const logout = () => (dispatch) =>
  dispatch(removeToken()).then(() => dispatch(logoutAction()));

export const login = (token, expires_at) => (dispatch) =>
  dispatch([setToken(token, expires_at), setData({ token })]);

export default handleActions(
  {
    [setData]: (state, action) => action.payload,
    [logoutAction]: () => ({})
  },
  {}
);
